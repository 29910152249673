import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';

import logo from '../assets/images/igf-logo-2016.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFan } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  const [items] = useState([
    { link: '', message: 'Home' },
    { link: 'what-we-do', message: 'What We Do' },
    { link: 'clients', message: 'Clients' },
    { link: 'about-us', message: 'About Us' },
    { link: 'contact', message: 'Contact' },
  ]);

  useEffect(() => {
    const fanElements = document.querySelectorAll('.fan');
    fanElements.forEach((fan) => {
      fan.addEventListener('click', () => {
        fan.classList.toggle('fa-spin');
      });
    });
    return () => {
      fanElements.forEach((fan) =>
        fan.removeEventListener('click', () => {
          fan.classList.toggle('fa-spin');
        })
      );
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>iguanaFarmGroup</title>
        <meta name="description" content="Description of the page." />
        <link rel="canonical" href="https://www.iguana-farm.com/" />
      </Helmet>

      <header>
        <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="Iguana Farm" />
            </a>
            <FontAwesomeIcon icon={faFan} className="fa fa-2x fa-spin fan" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                {items.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <Link
                      to={`/${item.link}`}
                      className="nav-link"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      {item.message}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <main className="py-4">
        <div className="container">{children}</div>
      </main>

      <footer className="pt-4 border-top">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md">
              <small className="d-block mb-1 text-muted">
                &copy; 1999-2024 Iguana-Farm.com LLC. All rights reserved.
              </small>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
