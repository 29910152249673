import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.scss';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const Contact = lazy(() => import('./pages/Contact'));
const Clients = lazy(() => import('./pages/Clients'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Router>
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="what-we-do" element={<Services />} />
            <Route path="clients" element={<Clients />} />
            <Route path="about-us" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Catch-all */}
          </Routes>
        </Suspense>
      </Layout>
    </Router>
  );
}

export default App;
